import React, { Component } from 'react';
import styled from 'styled-components';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as L from '../Utils/Lang';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';

class CategoryPolygon extends Component {
  constructor() {
    super();
    this.state = {
      mouseIn: false,
    };
  }

  _onItemClick = (item) => {
    let {onClick, navActions} = this.props;
    onClick ? onClick(item) : navActions.push(`/category/${item.id}`);
  }

  render() {
    let {
      cat = {
        display_name_tw: '建置中',
        display_name_en: 'In Process',
        id: 0,
        image: PathPrefix('/images/pages/category/cat_5.jpg'),
      },
      size = 220,
      extraCss = '',
      navActions,
      color = '#FFFFFF',
    } = this.props;
    let { mouseIn } = this.state;

    return (
      <Wrapper
        extraCss={extraCss}
        size={size}
        onMouseEnter={() => this.setState({ mouseIn: true })}
        onMouseLeave={() => this.setState({ mouseIn: false })}
      >
        <Desktop>
          <div
            dangerouslySetInnerHTML={{
              __html: `
                     <style>
                      rect.hover{
                        height: 100%;
                        width: 89%;
                        opacity: 1;
                      }

                      @media screen and (max-width: 1024px){
                        rect.hover{
                          opacity: 1;
                        }
                      }
                      </style>
                       <svg viewBox="-6 0 95 95" width="${size}px">
                            <defs>
                            <clipPath id="mask" style="cursor: pointer;">
                              <polygon id="mask-polygon" transform="" points="42.1508077 0 83 23.5842921 83 70.7528764 42.1508077 94.3371686 1.30161549 70.7528764 1.30161549 23.5842921"></polygon>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#mask)">
                            ${cat.image &&
                              `<image
                              preserveAspectRatio="xMidYMin slice"
                              xmlns:xlink= "http://www.w3.org/1999/xlink"
                              xlink:href=${
                                cat.image
                              } width="89%" height="100%" />`}
                           <rect class=${
                             mouseIn ? 'hover' : ''
                           }  fill="rgba(57, 57, 117, 0.4)"/>
                          </g>
                        </svg>
                    `,
            }}
          />

          <Hover
            show={mouseIn}
            onClick={() => this._onItemClick(cat)}
          >
            <h2
              style={{
                color,
                borderBottom: `5px solid ${color}`,
                textAlign: 'center',
                pointerEvents: 'none',
              }}
            >
              {L.d(cat, 'display_name')}
            </h2>
          </Hover>
        </Desktop>

        <Mobile>
          <div
            dangerouslySetInnerHTML={{
              __html: `
                     <style>
                      rect.hover{
                        height: 100%;
                        width: 89%;
                        opacity: 1;
                      }
                      </style>
                       <svg viewBox="-6 0 95 95" width="${size}px">
                            <defs>
                            <clipPath id="mask-mobile" style="cursor: pointer;">
                              <polygon id="mask-polygon-mobile" transform="" points="42.1508077 0 83 23.5842921 83 70.7528764 42.1508077 94.3371686 1.30161549 70.7528764 1.30161549 23.5842921"></polygon>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#mask-mobile)">
                            ${cat.image &&
                              `<image
                              preserveAspectRatio="xMidYMin slice"
                              xmlns:xlink= "http://www.w3.org/1999/xlink"
                              xlink:href=${
                                cat.image
                              } width="89%" height="100%" />`}
                           <rect class="hover"  fill="rgba(57, 57, 117, 0.4)"/>
                          </g>
                        </svg>
                    `,
            }}
          />

          <Hover
            show={true}
            onClick={() => this._onItemClick(cat)}
          >
            <h2
              style={{
                color,
                borderBottom: `5px solid ${color}`,
                textAlign: 'center',
                pointerEvents: 'none',
              }}
            >
              {L.d(cat, 'display_name')}
            </h2>
          </Hover>
        </Mobile>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.extraCss};
`;
let Hover = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  @media screen and (max-width: 1024px), screen and (orientation: landscape) {
    visibility: visible;
  }
`;

let Desktop = styled.div`
  position: absolute;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

let Mobile = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export default connect(
  null,
  ActionCreator
)(CategoryPolygon);
