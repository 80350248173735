import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import ActionCreator from '../ActionCreator';
import Selectors from '../Selectors';
import CategoryPolygon from '../Components/CategoryPolygon';
import PathPrefix from '../Utils/PathPrefixUtil';
import * as Widget from '../Components/Widget';

class PolygonGroup extends Component {
  render() {
    let { categories } = this.props;
    console.log('categories', categories);

    return (
      <PolygonWrapper>
        <Widget.Row
          extraCss={'transform: translate(0px, 47px);' + RwdResetTransCss}
        >
          <CategoryPolygon
            cat={this._getCatById(3)}
            extraCss={'transform: translate(10px, 0px);' + RwdResetTransCss}
          />
          <CategoryPolygon
              cat={this._getCatById(2)}
              extraCss={'transform: translate(-10px, 0px);' + RwdResetTransCss}
          />
        </Widget.Row>
        <Widget.Row extraCss={RwdResetTransCss}>
          <CategoryPolygon
            cat={this._getCatById(4)}
            extraCss={RwdResetTransCss + 'transform: translate(20px, 0px);'}
          />
          <CategoryPolygon
              cat={this._getCatById(0)}
              color={'#444344'}
              extraCss={RwdResetTransCss}
          />
          <CategoryPolygon
            cat={this._getCatById(5)}
            extraCss={'transform: translate(-20px, 0px);' + RwdResetTransCss}
          />
        </Widget.Row>
        <Widget.Row
          extraCss={'transform: translate(0px, -47px);' + RwdResetTransCss}
        >
          <CategoryPolygon
            cat={this._getCatById(6)}
            extraCss={'transform: translate(10px, 0px);' + RwdResetTransCss}
          />
          <CategoryPolygon
              cat={this._getCatById(7)}
              extraCss={'transform: translate(-10px, 0px);' + RwdResetTransCss}
          />
        </Widget.Row>
      </PolygonWrapper>
    );
  }

  _getCatById = id => {
    let { categories } = this.props;
    if (id === 0) {
      return {
        display_name_tw: '',
        display_name_en: '',
        id: 0,
        image: PathPrefix('/images/pages/category/cat_0.jpg'),
      };
    }
    return categories.find(c => c.id === id);
  };
}

let PolygonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    & > div {
      flex-direction: column;
    }
  }
`;

let RwdResetTransCss = css`
  @media screen and (max-width: 700px) {
    transform: translate(0, 0);
    margin-bottom: 10px;
  }
`;

export default connect(
  (state, ownProps) => ({
    categories: Selectors.getChigiCategories(state),
  }),
  ActionCreator
)(PolygonGroup);
