import React from 'react';
import styled from 'styled-components';
import { withPage } from '../Page';
import * as Widget from '../Components/Widget';
import PolygonGroup from '../Components/PolygonGroup';
import FeatureCollectionSection from '../Components/FeatureCollectionSection';

class CategoryListPage extends React.Component {

  render() {
    return (
      <Wrapper>
        <Widget.MaxWidth width={1000}>
            <PolygonGroup />
            <FeatureCollectionSection/>
        </Widget.MaxWidth>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  padding: 20px;
`;

export default withPage(CategoryListPage);
