import React from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import Link from './Link';
import {SquareItemsFlex, SquareItemsWrapper, DisplayNameOverlay} from './SquareItem';

class FeatureCollectionSection extends React.Component {
    render() {
        let { categories } = this.props;

        return (
            <Wrapper>
                <Header>
                    <h2>精選作品</h2>
                </Header>

                <Content>
                    {(() => {
                    let allCategoriesItems = [];

                    categories.forEach(category => {
                        category.own_items.forEach(item => {
                        allCategoriesItems.push(item);
                        })
                    });

                    return allCategoriesItems
                            .filter(item => item.is_special === true)
                            .map((item, idx) => (
                                <SquareItemsFlex key={idx}>
                                <SquareItemsWrapper image={item.image}>
                                    <Link
                                        to={`/archive/${item.id}`}
                                        extraStyle={{
                                        width: '100%',
                                        height: '100%',
                                        textDecoration: 'none',
                                        }}
                                    >
                                        <DisplayNameOverlay text={item.display_name_tw} />
                                    </Link>
                                </SquareItemsWrapper>
                                </SquareItemsFlex>
                    ))
                    })()}
                </Content>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div``;

let Header = styled.div`
  padding: 20px;

  & > h2 {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
  }
`;

let Content = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 540px) {
    justify-content: center;
  }
`;

export default connect(
    (state, ownProps) => ({
        // paramId = 0 is all categories.
        categories: Selectors.getChigiCategories(state, 0),
    }),
    ActionCreator
)(FeatureCollectionSection);